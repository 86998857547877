<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >课堂</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/course' }">我的课堂</el-breadcrumb-item>
                <el-breadcrumb-item>课程详情</el-breadcrumb-item>
                <el-breadcrumb-item>学习进度</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="padding-sm">
            <el-row type="flex" justify="start" align="middle">
                    <span class="margin-l-sm"><el-avatar :src="userInfo.avatar"></el-avatar></span>
                    <span class="margin-l-sm">{{userInfo.name}}</span>
                    <span class="margin-l-sm">已学{{percentage(studyCount/courseCount)}}</span>
            </el-row>

        </div>

        <div  class="padding-sm table-content" style="paddint-top:0;margin-top:0">
            <el-table
                    :header-cell-style="{padding:0}"
                    ref="singleTable"
                    :default-expand-all="true"
                    row-key="id"
                    :data="tableData"
                    :tree-props="{children: 'class', hasChildren: 'hasChildren'}"
                    highlight-current-row
                    v-loading="loading"
                    style="width: 100%;background: #fff;">
                <el-table-column
                        label="ID"
                        property="id"
                        width="80">
                </el-table-column>
                <el-table-column
                        label="名称">
                    <template slot-scope="scope">
                        <span>{{scope.row.name}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="类型">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type === 1">视频</span>
                        <span v-if="scope.row.type === 2">图文</span>
                    </template>
                </el-table-column>

                <el-table-column
                        property="address"
                        label="学习概要" width="200">
                    <template slot-scope="scope">
                        <div v-if="scope.row.count_duration !== undefined || scope.row.current_duration !== undefined">
                            <div >累计播放：{{sToTime(scope.row.count_duration)}}</div>
                            <div >当前播放：{{sToTime(scope.row.current_duration)}}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        property="address"
                        label="学习进度">
                    <template slot-scope="scope">
                            <span v-if="scope.row.duration !== undefined && scope.row.duration!==0">{{percentage(scope.row.current_duration/scope.row.duration)}} </span>
                    </template>
                </el-table-column>
                <el-table-column
                        property="address"
                        label="是否学完">
                    <template slot-scope="scope">
                            <span v-if="scope.row.is_finish !== undefined">{{scope.row.is_finish === 1 ? '是' : '否'}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        property="address"
                        label="学习次数">
                    <template slot-scope="scope">
                            <span v-if="scope.row.study_count !== undefined">{{scope.row.study_count}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        property="address"
                        label="最后学习时间">
                    <template slot-scope="scope">
                            <span>
                                {{scope.row.last_study_time}}
                            </span>
                    </template>
                </el-table-column>
            </el-table>

        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import tools from "../../../utils/tools";

    export default {
        name: "studyProgress",
        data(){
            return {
                courseCount:0,  //课程总数
                studyCount:0,   //已学课程
                userInfo:[],
                courseId:0,
                userId:0,
                loading:false,
                tableData: [{
                    id: 1,
                    date: '2016-05-02',
                    name: '第一章',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    id: 2,
                    date: '2016-05-04',
                    name: '第二章',
                    address: '上海市普陀区金沙江路 1517 弄'
                }, {
                    id: 3,
                    date: '2016-05-01',
                    name: '第三章',
                    address: '上海市普陀区金沙江路 1519 弄',
                    children: [{
                        id: 31,
                        date: '2016-05-01',
                        name: '王小虎',
                        address: '上海市普陀区金沙江路 1519 弄'
                    }, {
                        id: 32,
                        date: '2016-05-01',
                        name: '王小虎',
                        address: '上海市普陀区金沙江路 1519 弄'
                    }]
                }, {
                    id: 4,
                    date: '2016-05-03',
                    name: '第四章',
                    address: '上海市普陀区金沙江路 1516 弄'
                }],
            }
        },
        methods:{
            ...mapActions('course',['getUserStudyLog']),
            getList()
            {
                this.loading = true
                let data = {
                    courseId:this.courseId,
                    userId:this.userId
                }
                this.getUserStudyLog(data).then(res => {
                    console.log(res)

                    if(res.ret === 0){
                        this.tableData = res.data.log
                        this.userInfo = res.data.userInfo
                        this.courseCount = res.data.courseCount
                        this.studyCount = res.data.studyCount


                    }
                    this.loading = false
                })
            },
            sToTime(time){
                return tools.sToTime(time)
            },
            percentage(p)
            {
                return tools.percentage(p)
            }
        },
        mounted() {
            this.courseId = this.$route.params.courseId
            this.userId = this.$route.params.userId
            this.getList()

        }
    }
</script>

<style>
    .course-detail-title{
        margin:10px;
        background: #fafafa;
        overflow: hidden;
    }
    .title{
        font-size: 18px;
        margin-top:5px;
    }
    .price{
        margin-top:45px;
        font-size: 16px;
    }
</style>